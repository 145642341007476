import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Box, Toolbar } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ResponsiveAppBar from './components/ResponsiveAppBar';

//HOME
const Homepage = lazy(() => import('./pages/Homepage'));

//IPM Automation
const GlossaryExtraction = lazy(() => import('./pages/GlossaryExtraction'));
const DeadlineCalculator = lazy(() => import('./pages/DeadlineCalculator'));
const LanguageSeparator = lazy(() => import('./pages/LanguageSeparator'));
const PptCharCount = lazy(() => import('./pages/PptCharCount'));
const WhiteCellSeparator = lazy(() => import('./pages/WhiteCellSeparator'));
const IPM_Allocation = lazy(() => import('./pages/IPM_Allocation'));
const IPMPMTool = lazy(() => import('./pages/IPMPMTool'));

//ATPE Automation
const WorkloadAllocation = lazy(() => import('./pages/Allocation'));
const PMTool = lazy(() => import('./pages/PMTool'));
const Allocators = lazy(() => import('./pages/Allocators'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const WordCount = lazy(() => import('./pages/WordCount'));
const EditorTasks = lazy(() => import('./pages/EditorTasks'));
// const WorkloadTracker = lazy(() => import('./pages/Tracker'));

//Tamse Automation
const FileClassifier = lazy(() => import('./pages/FileClassifier'));

const theme = createTheme({
  palette: {
    primary: {
      main: '#262364',
    },
    secondary: {
      main: '#A82B69'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: 10,
          padding: 10,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 15,
          marginBottom:10,
          // height: '100%',
          // width: '100%'
        },
      },
    },
  },
});

function App() {
  return (
    <div className="App">
      <ResponsiveAppBar />
      <Box>
        <Toolbar />
          <ThemeProvider theme={theme}>
            <Routes>
              {/*HOME*/}
              <Route path='/' element={<Suspense fallback={<>...</>}> <Homepage /></Suspense>} />

              {/*IPM Automation*/}
              <Route path='/GlossaryExtraction' element={<Suspense fallback={<>...</>}> <GlossaryExtraction /></Suspense>} />
              <Route path='/DeadlineCalculator' element={<Suspense fallback={<>...</>}> <DeadlineCalculator /></Suspense>} />
              <Route path='/LanguageSeparator' element={<Suspense fallback={<>...</>}> <LanguageSeparator /></Suspense>} />
              <Route path='/PptCharCount' element={<Suspense fallback={<>...</>}> <PptCharCount /></Suspense>} />
              <Route path='/WhiteCellSeparator' element={<Suspense fallback={<>...</>}> <WhiteCellSeparator /></Suspense>} />
              <Route path='/IPMAllocation' element={<Suspense fallback={<>...</>}> <IPM_Allocation /></Suspense>} />
              <Route path='/IPMPMTool' element={<Suspense fallback={<>...</>}> <IPMPMTool /></Suspense>} />
              
              {/*ATPE Automation*/}
              <Route path='/WorkloadAllocation' element={<Suspense fallback={<>...</>}> <WorkloadAllocation /></Suspense>} />
              <Route path='/PMTool' element={<Suspense fallback={<>...</>}> <PMTool /></Suspense>} />
              <Route path='/Editors' element={<Suspense fallback={<>...</>}> <Allocators /></Suspense>} />
              <Route path='/Dashboard' element={<Suspense fallback={<>...</>}> <Dashboard/></Suspense>} />
              <Route path='/WordCount' element={<Suspense fallback={<>...</>}> <WordCount /></Suspense>} />
              <Route path='/EditorTasks' element={<Suspense fallback={<>...</>}> <EditorTasks /></Suspense>} />
              {/* <Route path='/WorkloadTracker' element={<Suspense fallback={<>...</>}> <WorkloadTracker /></Suspense>} /> */}

              {/*TAMSE Automation*/}
              <Route path='/FileClassifier' element={<Suspense fallback={<>...</>}> <FileClassifier /></Suspense>} />
            </Routes>
          </ThemeProvider>
      </Box>
    </div>
  );
}

export default App;
